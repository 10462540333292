import React, { useState } from "react";
import styled from "styled-components";
import { H3, Copy } from "../Text";
import Icon from "../../images/icons/info.svg";
import media from "../../styles/media";
import { defaultShadow } from "../styles/Shadow";

const Box = styled.div`
  ${defaultShadow}
  position: absolute;
  bottom: 0;
  transform: translateX(${props => (props.open ? 36.9 : 0)}rem);
  right: 0;
  width: 41.7rem;
  background-color: white;
  z-index: 2;
  display: flex;
  flex-direction: row;
  transition: 0.5s;
  border-radius: 4px 0 0 4px;
  ${media.down.lg`
    top: 0;
    left: 0;
    bottom: auto;
    flex-direction: column;
    width: 100%;
    transform: translateY(${props => (props.open ? "-100%" : 0)});
  `}
`;

const Button = styled.div`
  width: 4.8rem;
  border-radius: 0.3rem 0 0 0.3rem;
  background-color: #f3f3f3;
  border-right: 1px solid #dddddd;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  ${media.down.lg`
    width: 16rem;
    height: 2.4rem
    position: absolute;
    bottom: -2.4rem;
    left: 0;
    right: 0;
    margin: auto;
    border: 0;
    border-radius: 0 0 0.3rem 0.3rem;
    box-shadow: 0px 1px 8px rgba(0,0,0,0.2);
  `}
`;

const Content = styled.div`
  background: white;
  padding: 1.6rem 1.6rem 3.2rem;
  display: flex;
  flex-direction: column;
  z-index: 1;
  ${media.down.lg`
    width: 100%;
  `}
`;

const StyledCopy = styled(Copy)`
  margin-top: 0.8rem;
`;

const Ranges = styled.div`
  width: 100%;
  display: flex;
`;

const Range = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 1.6rem;
  width: 100%;
`;

const RangeLabel = styled(Copy)`
  font-size: 1.2rem;
`;

const RangeColor = styled.div`
  width: 100%;
  height: 0.8rem;
  background-color: ${props => props.color};
  margin-top: 0.4rem;
`;

const InfoBox = range => {
  const [open, setOpen] = useState(true);
  range = range.range;
  return (
    <Box open={open}>
      <Button onClick={() => setOpen(!open)}>
        <img src={Icon} alt="icon" />
      </Button>
      <Content>
        <H3>Schadenhäufigkeit</H3>
        <StyledCopy>
          Der Wert gibt an, wie viele Schadenfälle es pro 100 Kfz-Halter in den
          Landkreisen gibt.
        </StyledCopy>
        <Ranges>
          <Range>
            <RangeLabel>&lt;{range.ranges[0].from}</RangeLabel>
            <RangeColor color={range.lower_than_first_range}></RangeColor>
          </Range>
          {range.ranges.map((item, i) => (
            <Range key={i}>
              {i < range.ranges.length - 1 ? (
                <RangeLabel>{`${item.from}-${(
                  range.ranges[i + 1].from - 0.1
                ).toFixed(1)}`}</RangeLabel>
              ) : (
                <RangeLabel>&gt;{item.from}</RangeLabel>
              )}
              <RangeColor color={item.color}></RangeColor>
            </Range>
          ))}
          <Range>
            <RangeLabel>N/A</RangeLabel>
            <RangeColor color={range.na}></RangeColor>
          </Range>
        </Ranges>
      </Content>
    </Box>
  );
};

export default InfoBox;
